import React, { useState, useEffect, useCallback } from 'react'
import toast from 'react-hot-toast'
import { Card } from 'react-bootstrap-v5'
import { shallowEqual, useSelector } from 'react-redux'
import { TableColumn } from 'react-data-table-component'
import { PageTitle } from '../../../_metronic/layout/core'
import { RootState } from '../../../setup'
import { KTSVG, toServerUrl } from '../../../_metronic/helpers'
import { AddUserModal } from './component/AddUserModal'
import { UserModel, ConfigAlerts } from '../../../types'
import { ActionDropDown } from './component/ActionDropDown'
import { getErrorMessage } from '../../../helper/response.helper'
import DataTableBase from '../../components/DataTableBase'
import {
  getReceiveApprovals,
  updateReceiveApproval,
  deleteReceiveApproval,
  deleteReceiveApprovals
} from './ReceiveAlertsCrud'

const ReceiveGetServiceRequsts: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [approvals, setApprovals] = useState<ConfigAlerts[]>([])
  const [selectedData, setSelectedData] = useState<ConfigAlerts[]>([]) 
  const [slug, setSlug] = useState<string>('')

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  
  const columns: TableColumn<ConfigAlerts>[] = [
    {
      name: 'USER',
      cell: row => 
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
            <div className='symbol-label'>
              <img src={toServerUrl(row.user_avatar)} alt="avatar" width='100%' />
            </div>
          </div>
          <div className='d-flex flex-column'>
            <span className='text-gray-800 text-hover-primary mb-1'>{row.user_name}</span>
            <span>{row.user_email}</span>
          </div>
        </div>
      ,
      style: {
        maxWidth: 'unset !important',
      }
    },
    {
      name: 'RECEIVE BY NOTIFICATION',
      cell: row => 
        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <input
            name='receive notification'
            type='checkbox'
            className='form-check-input'
            onChange={(e) => handleChange(row.dbkey, e.target.checked, 'notification')}
            defaultChecked={row.rcv_by_notification}
          />
        </label>
      ,
      style: {
        maxWidth: 'unset !important',
      }
    },
    {
      name: 'RECEIVE BY EMAIL',
      cell: row => 
        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <input
            name='receive email'
            type='checkbox'
            className='form-check-input'
            onChange={(e) => handleChange(row.dbkey, e.target.checked, 'email')}
            defaultChecked={row.rcv_by_email}
          />
        </label>
      ,
      style: {
        maxWidth: 'unset !important',
      }
    },
    {
      name: 'ACTIONS',
      maxWidth: 'unset !important',
      cell: row => 
      <ActionDropDown 
        data={row}
        handleDelete={() => handleDelete(row.dbkey)}
      />,
      width: '100px'
    }
  ];

  const handleSearch = (value: string) => {
    setSlug(value)
  }

  const handleAddUser = () => {
    setShowAddModal(true)
  }

  const handleDelete = (dbkey: string) => {
    deleteReceiveApproval(dbkey)
    .then((res) => {
      toast.success(res.data)
      getApprovals()
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }

  const handleDeleteUsers = () => {
    let dbKeys: string[] = []

    selectedData.forEach(data => {
      dbKeys.push(data.dbkey)
    })

    deleteReceiveApprovals(dbKeys)
    .then((res) => {
      toast.success(res.data)
      setSelectedData([])
      getApprovals()
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }

  const handleChange = (dbkey: string, isSet: boolean, name: string) => {
    updateReceiveApproval({
      dbkey: dbkey, isSet: isSet, name: name
    }).then((res) => {
      toast.success(res.data)
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }

  const handleRowsChange = (state: any) => {
    setSelectedData(state.selectedRows);
  }

  const handleCloseModal = () => {
    setShowAddModal(false)
  }

  const getApprovals = useCallback(() => {
    setIsLoading(true)
      getReceiveApprovals('rcv_getservice_requests', slug === '' ? 'startup' : slug)
      .then((res) => {
        setApprovals(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
    }, [slug]
  )

  useEffect(() => {
    getApprovals()
  }, [getApprovals, slug])

  useEffect(() => {
    if (selectedData.length === 0)
      setShowDelete(false)
    else
      setShowDelete(true)
  }, [selectedData])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Receive Get Service Notifications</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className="border-0 pt-10">
          <Card.Title>
            <div className="d-flex align-items-center position-relative my-1">
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input 
                type='text' 
                className='form-control form-control-solid w-250px ps-15'
                name='search'
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search user' 
              />
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end'>
              <button type='button' className='btn btn-primary' onClick={handleAddUser}>
                <KTSVG
                  path='/media/icons/duotone/Navigation/Plus.svg'
                  className='svg-icon-2'
                />
                Add User
              </button>
              {
                showDelete &&
                <button type='button' className='btn btn-danger ms-3' onClick={handleDeleteUsers}>
                  <KTSVG
                    path='/media/icons/duotone/Navigation/Minus.svg'
                    className='svg-icon-2'
                  />
                  Delete Users
                </button>
              }
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className='file-management-table-wrapper'>
            <DataTableBase
              columns={columns}
              data={approvals}
              progressPending={isLoading}
              selectableRows
              onSelectedRowsChange={handleRowsChange}
              onSort={() => {}}
            />
          </div>
        </Card.Body>
      </Card>
      <AddUserModal
        show={showAddModal}
        userId={user.id}
        section='rcv_getservice_requests'
        onHide={handleCloseModal}
        getApprovals={getApprovals}
      />
    </>
  )
}

export default ReceiveGetServiceRequsts
