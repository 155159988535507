import React, { useState, useEffect } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Accordion } from 'react-bootstrap-v5'
import { SearchDropDown } from '../../../components/SearchDropDown'
import { getAllCustomerIds } from '../../../pages/asset-mangement/AssetManagementCrud'
import { UserModel, Customer } from '../../../../types'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import CustomToggle from '../../../components/CustomToggle'
import FilterList from '../../../components/FilterList'
import * as levelHelper from '../../../../helper/level.helper'
import 'react-datepicker/src/stylesheets/datepicker.scss'

type FilterDropDownProps = {
  handleFilter: (filter: any) => void
}

const FilterDropDown: React.FC<FilterDropDownProps> = ({ handleFilter }) => {
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('')
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>()
  const [statusFilters, setStatusFilters] = useState<Array<string>>(['Active', 'Approved'])
  const [transFilters, setTransFilters] = useState<Array<string>>(['Cal_Lab', 'Service', 'Repair'])
  const [customers, setCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const transList = ['Cal_Lab', 'Service', 'Repair']
  const statusList = ['Active', 'Approved', 'Inactive', 'Canceled', 'Invoiced', 'Service complete', 'Sold']

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const handleChange = (status: string, isChecked: boolean) => {
    if (isChecked === true)
      setStatusFilters([...statusFilters, status === 'Service complete' ? 'service_c' : status])
    else
      setStatusFilters(statusFilters.filter(s => s !== (status === 'Service complete' ? 'service_c' : status)))
  }

  const handleChangeTrans = (status: string, isChecked: boolean) => {
    if (isChecked === true)
      setTransFilters([...transFilters, status])
    else
      setTransFilters(transFilters.filter(s => s !== status))
  }

  const handleReject = () => {
    setStatusFilters(['Active', 'Approved'])
    setTransFilters(['Cal_Lab', 'Service', 'Repair'])
    setStartDate('')
    setEndDate('')
    setIsReset(true)
    handleFilter({
      isReset: true,
    })
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(user?.type))
  }, [user])

  useEffect(() => {
    if (isNBS)
      getAllCustomerIds()
      .then((res) => {
        setCustomers(res.data)
      })
  }, [isNBS])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey="0">
          {
            isNBS &&
            <>
              <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
                <CustomToggle eventKey='filter-0'>Customer ID</CustomToggle>
              </div>
              <Accordion.Collapse eventKey='filter-0' className="ms-8">
                <SearchDropDown
                  data={customers}
                  edit={isReset ? 'reset' : ''}
                  isNBS={isNBS}
                  selectedValue={(customer)=> {
                    setIsReset(false)
                    setSelectedCustomerId(customer.id)
                  }}
                />
              </Accordion.Collapse>
            </>
          }
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-1'>Status</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-1' className="ms-8">
            <ul className='list-unstyled'>
              {
                statusList.map((status, index) => {
                  return (
                    <FilterList
                      key={`status-${index}`}
                      status={status}
                      isChecked={statusFilters.includes(status) && true}
                      handleChange={handleChange}
                    />
                  );
                })
              }
            </ul>
          </Accordion.Collapse>
          <div className='separator border-gray-200'></div>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-3'>Transaction Type</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-3' className="ms-8">
            <ul className='list-unstyled'>
              {
                transList.map((trans, index) => {
                  return (
                    <FilterList
                      key={`trans-${index}`}
                      status={trans}
                      isChecked={true}
                      handleChange={handleChangeTrans}
                    />
                  )
                })
              }
            </ul>
          </Accordion.Collapse>
          <div className='separator border-gray-200'></div>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
              <CustomToggle eventKey='filter-2'>Created on</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-2'>
            <>
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className="form-control"
                  placeholderText="Select a date"
                  selected={startDate}
                  dateFormat="MM/dd/yy hh:mma"
                  showTimeSelect
                  onChange={(date) => { setStartDate(date) }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className="form-control"
                  placeholderText="Select a date"
                  selected={endDate}
                  dateFormat="MM/dd/yy hh:mma"
                  showTimeSelect
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setIsReset(false)
                handleFilter({
                  customerId: selectedCustomerId,
                  status: statusFilters,
                  from: moment(startDate).utc().format("Y-MM-DD HH:mm"),
                  to: endDate !== null ? moment(endDate).utc().format("Y-MM-DD HH:mm") : moment().utc().format("Y-MM-DD HH:mm"),
                  transType: transFilters
                })
              }}
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
