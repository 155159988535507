import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from "redux-saga/effects";
import { QuoteModel } from '../../../types';
import { getSalesQuotes } from "./salesQuotesCrud";

export interface ActionWithPayload<T> extends Action {
  payload? : T
}

export const actionTypes = {
  SalesQuotesRequested: "[Request Sales Quotes] Action",
  SalesQuotesLoaded: "[Load Sales Quotes] Action",
};

const initialQuotesState: ISalesQuotesState = {
  loading: false,
  salesQuotes: []
};

export interface ISalesQuotesState {
  loading: boolean
  salesQuotes: any
}

export const reducer = (
  state: ISalesQuotesState = initialQuotesState, 
  action: ActionWithPayload<ISalesQuotesState>
) => {
  switch (action.type) {
    case actionTypes.SalesQuotesRequested: {
      return {...state, loading: true}
    }

    case actionTypes.SalesQuotesLoaded: {
      const salesQuotes = action.payload?.salesQuotes
      return { loading: false, salesQuotes}
    }

    default:
      return state;
  }
}


export const actions = {
  getSalesQuotes: (searchFilter: any = {}) => ({ 
    type: actionTypes.SalesQuotesRequested, 
    payload: { searchFilter } 
  }),
  salesQuotesLoaded: (salesQuotes: QuoteModel[]) => ({ 
    type: actionTypes.SalesQuotesLoaded, 
    payload: { salesQuotes } 
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.SalesQuotesRequested, function* salesQuotesRequested(action: any) {
    const { searchFilter } = action.payload;
    const { data: salesQuotes } = yield getSalesQuotes(searchFilter);

    yield put(actions.salesQuotesLoaded(salesQuotes));
  });
}
