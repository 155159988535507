import React, { useState, useEffect } from "react";
import getContactEmail from "./footerCrud";

const ContactLink = () => {
  const [mailToEmail, setMailtoEmail] = useState<string>("mailto: jesse@threesdesign.net")
  useEffect(() => {
    getContactEmail()
    .then((res) => {
      setMailtoEmail(res.data)
    }).catch((res) => {
      setMailtoEmail("mailto: jesse@threesdesign.net");
    })
  }, [])
  return (
    <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
      <li className='menu-item'>
          <a href={mailToEmail} id="mailToId" className='menu-link pe-0 pe-2'>
            Contact
          </a>
      </li>
    </ul>
  )
}

export default ContactLink;
