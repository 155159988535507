import React, { FC } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { NotificationModel } from '../../../../types'

type Props = {
  data: NotificationModel
  markRead: (id: string) => void 

}
const Notification: FC<Props> = ({ data, markRead }) => {
  return (
    <div
      className={`d-flex align-items-center rounded p-5 mb-4 ${!!data.read_at ? 'bg-light-success' : 'bg-light-danger'}`}
    >
      <span className='svg-icon svg-icon-warning me-5'>
        <KTSVG path='/media/icons/duotone/Home/Library.svg' className='svg-icon-1' />
      </span>
      <div className='flex-grow-1 me-2'>
        <span className='fw-bolder text-gray-800 text-hover-primary fs-6'>
          { data.data.subject }
        </span>
        <span className='text-muted fw-bold d-block'>
          + {data.data.description}
        </span>
      </div>
      <div className='w-15px'>
        <input
          className='form-check-input cursor-pointer'
          type='checkbox'
          value=''
          name='notifications'
          onChange={() => markRead(data.id)}
          disabled={!!data.read_at ? true : false}
          defaultChecked={!!data.read_at ? true : false}
        />
      </div>
    </div>
  )
}

export { Notification }