import axios from 'axios'

export const QUOTES_URL = `${process.env.REACT_APP_API_URL}/sales-orders`

export function getSalesOrders(searchFilter = {}) {
  return axios.post(QUOTES_URL, { searchFilter })
}

export function getFilterStatus(searchFilter = {}) {
  return axios.get(`${QUOTES_URL}/get-filter-status`)
}
