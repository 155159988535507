import React, { useState } from 'react'
import { saveAssets } from '../AssetManagementCrud'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { SaveModal } from '../../../../types'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../../helper/response.helper'
import Table from './Table'

type Props = {
  data: SaveModal
  onHide: any
  userId: number | undefined
  getAssets: () => void
}

const SaveImportedAssetsModal: React.FC<Props> = (props) => {
  const { data, onHide, userId, getAssets } = props
  const [submit, setSubmit] = useState<boolean>(false)
  const headers = ['Make', 'Model', 'Serial', 'Unite ID', 'Department', 'Equipment Type']

  const handleSubmit = () => {
    setSubmit(true)
    const importing = {
      userId: userId,
      customer_id: data.customerId,
      mode: data.mode,
      assets: data.assets
    }

    saveAssets(importing)
    .then((res) => {
      toast.success(res.data)
      setSubmit(false)
      onHide(false)
      getAssets()
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }

  return (
    <Modal size='lg' 
      show={data.show}
      dialogClassName="w-50"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id="contained-modal-title-vcenter">
          <div className='d-flex justify-content-between'>
            <h2>Import Assets</h2>
            <span className='text-hover-primary cursor-pointer' onClick={() => onHide(true)}>
              <KTSVG
                path='/media/icons/duotone/Navigation/Close.svg'
                className='svg-icon-1'
              />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <p className='fw-bold fs-6 mb-2 text-center'>
              You are about to {data.mode} your assets with the below imported asset list. Please verify and select
              <b> "Import Now"</b> to {data.mode} your asset list or <b>"Discard"</b> to cancel.
            </p>
          </div>
          <div className='fv-row mb-7 fv-plugins-icon-container max-height-60 scroll-y'>
            <Table headers={headers} rows={data.assets} />
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='button'
            className='btn btn-light me-3'
            onClick={() => onHide(true)}
          >
            Discard
          </button>
          <button
            type='submit'
            className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}
            onClick={handleSubmit}
          >
            Import Now
            {
              submit &&
              <div
                className="spinner-border w-17px h-17px ms-2 text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { SaveImportedAssetsModal }