import React, { useState, useEffect, useRef } from 'react'
import { importAssets } from '../AssetManagementCrud'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { ExtensionDropDown } from './ExtensionDropDown'
import { getAllCustomerIds } from '../AssetManagementCrud'
import { SearchDropDown } from './SearchDropDown'
import { fileType, SaveModal } from '../../../../types'
import { convertByteTo } from '../../../../helper/learningCenter.helper'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../../helper/response.helper'

type Props = {
  show: boolean
  isNBS: boolean
  customerProp: { id: string, name: string | undefined }[]
  onHide: any
  extensions: string[]
  modes: string[]
  refresh: boolean
  saveImport: (data: SaveModal) => void
  getAssets: () => void
}

const ImportAssetsModal: React.FC<Props> = (props) => {
  const { show, isNBS, customerProp, onHide, extensions, modes, refresh, saveImport, getAssets } = props
  const [showDesc, setShowDesc] = useState<boolean>(false)
  const [extension, setExtension] = useState<string>(extensions[0])
  const [mode, setMode] = useState<string>(modes[0])
  const [customerId, setCustomerId] = useState<{ id: string, name: string | undefined }>({ id: '', name: '' })
  const [customers, setCustomers] = useState<{ id: string, name: string | undefined }[]>([])
  const [selectedFile, setSelectedFile] = useState<fileType>({ name: '', size: 0, type: '' })
  const [allFile, setAllFile] = useState<File[]>([])
  const [validate, setValidate] = useState<boolean>(false)
  const [submit, setSubmit] = useState<boolean>(false)
  const [fileAccept, setFileAccept] = useState<string>('application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  const [errorText, setErrorText] = useState<string>('')

  const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const openInputFile = () => {
    inputFileRef.current.click()

    return false
  }

  const handleSubmit = () => {
    setValidate(false)
    
    if (customerId.id === '') {
      setErrorText('Customer id was not selected, please select customer id.')
      return setValidate(true)
    }
      
    if (allFile.length === 0) {
      setErrorText('File was not uploaded, please upload a file.')
      return setValidate(true)
    }

    setSubmit(true)

    let dt = new DataTransfer()

    allFile.forEach((file) => {
      dt.items.add(file)
    })

    const attachFiles = {
      'files': dt.files
    }

    const importSetting = {
      extension: extension,
      mode: mode,
      attachFiles: attachFiles
    }
    
    importAssets(importSetting)
    .then((res) => {
      if (typeof res.data === 'string') {
        setErrorText(res.data)
        setValidate(true)
        setSubmit(false)
      } else {
        setSubmit(false)
        setValidate(false)
        onHide(false)
        saveImport({show: true, customerId: customerId.id, mode, assets: res.data})
        getAssets()
      }
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }

  const handleSelectExtension = (ext: string) => {
    setExtension(ext)

    if (ext === 'Excel')
      setFileAccept('application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    else
      setFileAccept('.csv')
  }

  const handleSelectMode = (selectMode: string) => {
    setMode(selectMode)
  }

  const handleChange = (event: any) => {
    const file = event.currentTarget.files[0]
    
    let fetchFile = { name: file.name, size: file.size, type: '' }

    setSelectedFile(fetchFile)
    setAllFile([file])
  }

  const handleCancelFile = (event: any) => {
    setSelectedFile({ name: '', size: 0, type: '' })
    setAllFile([])
  }

  const selectCustomer = (value: { id: string, name: string | undefined }) => {
    setCustomerId(value)
    setValidate(false)
  }

  useEffect(() => {
    if (isNBS)
      getAllCustomerIds()
      .then((res) => {
        setCustomers(res.data)
      })
    else {
      setCustomers(customerProp)
    }
  }, [isNBS, customerProp])

  useEffect(() => {
    if (refresh === true) {
      setSelectedFile({ name: '', size: 0, type: '' })
      setAllFile([])
      setErrorText('')
    }
  }, [refresh])

  return (
    <Modal size='lg' 
      show={show}
      dialogClassName="w-50"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id="contained-modal-title-vcenter">
          <div className='d-flex justify-content-between'>
            <h2>Import Assets</h2>
            <div className='d-flex'>
              <div className='position-relative'>
                <span
                  className='cursor-pointer'
                  onMouseEnter={() => setShowDesc(true)}
                  onMouseLeave={() => setShowDesc(false)}
                >
                  <KTSVG
                    path='/media/icons/duotone/Code/Question-circle.svg'
                    className='svg-icon-2'
                  />
                </span>
                <div className={`w-250px position-absolute asset-desc-box ${!showDesc && 'd-none'}`}>
                  This is used to import your assets into the Portal. The file you select must have columns in the following order. <br />
                  Make, Model, Serial, Unit ID, Department, Equipment Type.
                </div>
              </div>
              <span className='text-hover-primary cursor-pointer' onClick={() => onHide(true)}>
                <KTSVG
                  path='/media/icons/duotone/Navigation/Close.svg'
                  className='svg-icon-1'
                />
              </span>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='required fw-bold fs-6 mb-2'>Customer ID</label>
            <SearchDropDown data={customers} edit='' isNBS={false} selectedValue={selectCustomer} />
          </div>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='fw-bold fs-6 mb-2 pt-1 required'>Select Export Format: </label>
            <ExtensionDropDown
              extension={extension}
              extensions={extensions}
              selectExtension={handleSelectExtension}
            />
          </div>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='fw-bold fs-6 mb-2 pt-1 required'>Replace or Append to Assets: </label>
            <ExtensionDropDown
              extension={mode}
              extensions={modes}
              selectExtension={handleSelectMode}
            />
          </div>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <div className='d-flex justify-content-between'>
              <span className='required'>Attachment</span>
              <span className='btn btn-primary btn-elevate p-2' onClick={openInputFile}>
                <KTSVG
                  path='/media/icons/duotone/Files/UPload-folder.svg'
                  className='svg-icon-2 '
                />
                <span>Upload Files</span>
              </span>
              <input
                  type='file'
                  name='files'
                  className='form-control d-none'
                  onChange={ handleChange }
                  accept={ fileAccept }
                  ref={ inputFileRef }
                />
            </div>
            <div className='dropzone-items wm-200px'>
              {
                selectedFile.name !== '' &&
                <div className='d-flex justify-content-between p-4 background-light-dark my-2 cursor-pointer'>
                  <span>{selectedFile.name} {selectedFile.size !== 0 && `(${convertByteTo(selectedFile.size)})`}</span>
                  <span
                    className='text-hover-primary cursor-pointer'
                    onClick={(event) => handleCancelFile(event)}
                  >
                    <KTSVG
                      path='/media/icons/duotone/Navigation/Close.svg'
                      className='svg-icon-1'
                    />
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
        <div className={`fv-row mb-7 fv-plugins-icon-container ${validate ? 'd-block' : 'd-none'}`}>
          <label className='fs-6 mb-2 pt-1 text-danger'>{errorText}</label>
        </div>
        <div className='text-center pt-15'>
          <button
            type='button'
            className='btn btn-light me-3'
            onClick={() => onHide(true)}
          >
            Discard
          </button>
          <button
            type='submit'
            className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}
            onClick={handleSubmit}
          >
            Submit
            {
              submit &&
              <div
                className="spinner-border w-17px h-17px ms-2 text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { ImportAssetsModal }