const QuillEditorToolbar = () => (
    <div id="toolbar-container">
        <span className="ql-formats">
            <button type="button" className="ql-header" value="1"></button>
            <button type="button" className="ql-header" value="2"></button>

            <select className="ql-header">
                <option value="3"></option>
                <option value="4"></option>
                <option value="5"></option>
                <option value="6"></option>
            </select>

            <select className="ql-font">
                <option defaultValue></option>
                <option value="serif"></option>
                <option value="monospace"></option>
            </select>
        </span>

        <span className="ql-formats">
            <select className="ql-size">
                <option value="small"></option>
                <option defaultValue></option>
                <option value="large"></option>
                <option value="huge"></option>
            </select>
        </span>
        
        <span className="ql-formats">
            <button type="button" className="ql-bold"></button>
            <button type="button" className="ql-italic"></button>
            <button type="button" className="ql-underline"></button>
            <button type="button" className="ql-strike"></button>
            <button type="button" className="ql-blockquote"></button>
        </span>
        
        <span className="ql-formats">
            <button type="button" className="ql-list" value="ordered"></button>
            <button type="button" className="ql-list" value="bullet"></button>
        </span>

        <span className="ql-formats">
            <button type="button" className="ql-link" data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Insert link'></button>
            <button type="button" className="ql-image" data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Insert Attachment'></button>
            <button type="button" className="ql-video" data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Insert Youtube video'></button>
        </span>

        <span className="ql-formats">
            <button type="button" className="ql-clean"></button>
        </span>

        <span className="ql-formats">
            <button type="button" className="ql-code-block"></button>
        </span>
    </div>
)

export default QuillEditorToolbar