import React, { useState, useEffect } from 'react'

const FilterList = (props: any) => {
  const { status, isChecked, handleChange } = props
  const [checked, setChecked] = useState<boolean>(isChecked)

  useEffect(() => {
    setChecked(isChecked)
  }, [status, isChecked])

  return (
    <li
      className='d-flex justify-content-end'
      onClick={() => { handleChange(status, !checked); setChecked(!checked) }}
    >
      <button className='btn p-2 ps-0 w-100 text-start'>
        {status === 'Cal_Lab' ? 'Cal Lab' : status}
      </button>
      <input
        className='form-check-input w-17 h-17 mt-2'
        type='checkbox'
        name='filters'
        checked={checked}
        readOnly
      />
    </li>
  )
}

export default FilterList