import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import * as Yup from 'yup'

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  po: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('PO is required'),
  comment: Yup.string()
    .required('Comment is required'),
  attachment: Yup.string()
    .required('Attachment is required'),
})

const initialValues = {
  po: '',
  paymentType: 'po',
  hardCopyAvailable: '',
  comment: '',
  attachment: [],
}

const ApproveModal = ({ submit, status, quoteNumber, show, onHide, showConfirmAlert }) => {
  const [loading, setLoading] = useState(false)
  const [isfileSelected, setIsFileSelected] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [allFiles, setAllFiles] = useState([])

  const inputFileRef = useRef()

  const openInputFile = () => {
    inputFileRef.current.click()

    return false
  }

  const handleChangeFiles = (files) => {
    let fetchFiles = []

    for (let file of files)
      fetchFiles.push({ name: file.name, size: file.size })

    setSelectedFiles([...selectedFiles, ...fetchFiles])
    setIsFileSelected(true)
  }

  const handleCancelFile = (name) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== name))

    let removedFiles = []

    for (let file of allFiles) {
      if (file.name !== name)
        removedFiles.push(file)
    }

    setAllFiles(removedFiles)
  }

  const handleSubmit = (values) => {
    setLoading(true)
    submit(values)
  }

  useEffect(() => {
    if (status)
      setLoading(false)
  }, [status])

  return (
    <Modal size='lg' show={show} onHide={onHide} aria-labelledby='example-modal-sizes-title-lg'>
      <Modal.Header>
        <Modal.Title>Service Quote-Approval</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={CustomerEditSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
          <>
            <Modal.Body className='overlay cursor-default'>
              {loading && (
                <div className='overlay-layer bg-transparent'>
                  <div className='spinner spinner-lg spinner-success' />
                </div>
              )}
              <Form className='form form-label-right'>
                <div className='form-group row'>
                  <div className='col-lg-12'>
                    <span>Service Quote: {quoteNumber}</span>
                  </div>
                  {/* PO */}
                  <div className='col-lg-6 mt-5'>
                    <span className='required'>
                      {
                        values.paymentType === 'cc' ? 'Credit Card Number' :
                          values.paymentType === 'po' ? 'Purchase Order Number' : 'Other'
                      }
                    </span>
                    <Field name={
                      values.paymentType === 'cc' ? 'cc' :
                        values.paymentType === 'po' ? 'po' : 'other'
                    } as='input'
                      placeholder={
                        values.paymentType === 'cc' ? 'Enter C.C Number' :
                          values.paymentType === 'po' ? 'Enter P.O Number' : 'Other'
                      } className='form-control'
                    />
                    {touched.po && errors.po ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.po}</div>
                      </div>
                    ) : null}
                  </div>
                  {/* Payment Type */}
                  <div className='col-lg-6 mt-5'>
                    <span className='required'>Payment Option</span>
                    <select
                      className='form-control'
                      name='paymentType'
                      data-control='select2'
                      label='Payment Type'
                      onChange={handleChange}
                    >
                      <option value='po'>Purchase Order</option>
                      <option value='cc'>Credit Card</option>
                      <option value='other'>Other</option>
                    </select>

                    {values.paymentType === 'cc' && (
                      <div className='mt-2 color-red'>
                        Your NBS Rep will contact you for CC information
                      </div>
                    )}
                  </div>

                </div>

                {/* Comment */}
                <div className='form-group mt-5'>
                  <span className='required'>Approval Comments</span>
                  <Field
                    name='comment'
                    as='textarea'
                    rows='5'
                    placeholder='Enter Approval Comments'
                    className='form-control'
                  />
                  {touched.comment && errors.comment ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.comment}</div>
                    </div>
                  ) : null}
                </div>

                {/* Attachment */}
                <div className='form-group mt-5'>
                  <div className='d-flex justify-content-between'>
                    <span className='required'>Attachment</span>
                    <span className='btn btn-primary btn-elevate p-2' onClick={openInputFile}>
                      <KTSVG
                        path='/media/icons/duotone/Files/UPload-folder.svg'
                        className='svg-icon-2 '
                      />
                      <span>Upload Files</span>
                    </span>
                  </div>
                  <input
                    type='file'
                    name='attachment[]'
                    className='form-control d-none'
                    ref={inputFileRef}
                    onChange={(event) => {
                      let files = event.currentTarget.files
                      let attachedFiles = [...allFiles, ...files]
                      let dt = new DataTransfer()

                      attachedFiles.forEach((file) => {
                        dt.items.add(file)
                      })

                      setFieldValue('attachment', dt.files)

                      handleChangeFiles(files)
                      setAllFiles(attachedFiles)
                    }}
                    multiple
                  />
                  <div className='my-5'>
                    {isfileSelected &&
                      selectedFiles?.map((file, index) => {
                        return (
                          <div key={index} className='d-flex justify-content-between p-3 background-light-dark my-2 cursor-pointer'>
                            <span>{file.name} ({file.size} KB)</span>
                            <span className="svg-icon svg-icon-1" onClick={() => handleCancelFile(file.name)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                              </svg>
                            </span>
                          </div>
                        )
                      })
                    }
                  </div>

                  {touched.attachment && errors.attachment ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.attachment}</div>
                    </div>
                  ) : null}
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button type='button'
                onClick={() => {
                  onHide()
                  setIsFileSelected(false)
                  setSelectedFiles([])
                  setAllFiles([])
                }}
                className='btn btn-light btn-elevate'>
                Cancel
              </button>
              <> </>
              <button
                type='submit'
                onClick={() => {
                  if (values.po !== '' && values.comment !== '' && values.attachment !== null) {
                    const CONFIRM_ALERT_CONTENT = `You are about to approve quote # ${quoteNumber}.  A new order with PO# ${values.po} will be created and will be submitted to our billing department. Would you like to continue?`
                    showConfirmAlert(CONFIRM_ALERT_CONTENT, handleSubmit)
                  } else {
                    handleSubmit()
                  }

                }}
                className='btn btn-primary btn-elevate'
                disabled={loading}
              >
                {!loading && <span className='indicator-label'>Approve</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default ApproveModal
