import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { QuoteModel } from '../../../types'
import { getSalesOrders } from './salesOrdersCrud'

export interface ActionWithPayload<T> extends Action {
  payload? : T
}

export const actionTypes = {
  SalesOrdersRequested: '[GetSalesOrders] Action',
  SalesOrdersLoaded: "[Load Sales Orders] Action",
}

const initialQuotesState: ISalesOrdersState = {
  salesOrders: [],
  loading: false,
}

export interface ISalesOrdersState {
  salesOrders: any
  loading: boolean
}

export const reducer = (
  state: ISalesOrdersState = initialQuotesState,
  action: ActionWithPayload<ISalesOrdersState>
) => {
  switch (action.type) {
    case actionTypes.SalesOrdersRequested: {
      return {...state, loading: true}
    }
    case actionTypes.SalesOrdersLoaded: {
      const salesOrders = action.payload?.salesOrders
      return { loading: false, salesOrders}
    }
    default:
      return state
  }
}

export const actions = {
  getSalesOrders: (searchFilter: any = {}) => ({
    type: actionTypes.SalesOrdersRequested,
    payload: { searchFilter },
  }),
  salesOrdersLoaded: (salesOrders: QuoteModel[]) => ({ 
    type: actionTypes.SalesOrdersLoaded, 
    payload: { salesOrders } 
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.SalesOrdersRequested, function* salesOrdersRequested(action: any) {
    const { searchFilter } = action.payload
    const { data: salesOrders } = yield getSalesOrders(searchFilter)

    yield put(actions.salesOrdersLoaded(salesOrders))
  })
}
