import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from "redux-saga/effects";
import { QuoteModel } from '../../../types';
import { getServiceQuotes } from "./serviceQuotesCrud";

export interface ActionWithPayload<T> extends Action {
  payload? : T
}

export const actionTypes = {
  ServiceQuotesRequested: "[Request Service Quotes] Action",
  ServiceQuotesLoaded: "[Load Service Quotes] Action",
};

const initialQuotesState: IServiceQuotesState = {
  loading: false,
  serviceQuotes: []
};

export interface IServiceQuotesState {
  loading: boolean
  serviceQuotes: any
}

export const reducer = (
  state: IServiceQuotesState = initialQuotesState, 
  action: ActionWithPayload<IServiceQuotesState>
) => {
  switch (action.type) {
    case actionTypes.ServiceQuotesRequested: {
      return {...state, loading: true}
    }

    case actionTypes.ServiceQuotesLoaded: {
      const serviceQuotes = action.payload?.serviceQuotes
      return { loading: false, serviceQuotes}
    }

    default:
      return state;
  }
}


export const actions = {
  getServiceQuotes: (searchFilter: any = {}) => ({ 
    type: actionTypes.ServiceQuotesRequested, 
    payload: { searchFilter } 
  }),
  serviceQuotesLoaded: (serviceQuotes: QuoteModel[]) => ({ 
    type: actionTypes.ServiceQuotesLoaded, 
    payload: { serviceQuotes } 
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.ServiceQuotesRequested, function* serviceQuotesRequested(action: any) {
    const { searchFilter } = action.payload;
    const { data: serviceQuotes } = yield getServiceQuotes(searchFilter);

    yield put(actions.serviceQuotesLoaded(serviceQuotes));
  });
}
