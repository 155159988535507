/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Card } from 'react-bootstrap-v5'
import toast from 'react-hot-toast'
import moment from 'moment'
import { PageTitle } from '../../../_metronic/layout/core'
import { AttachmentModal } from '../service-quotes/components/AttachmentModal'
import { getQuoteAttachment } from '../service-quotes/serviceQuotesCrud'
import { getErrorMessage, customQuotesSort } from '../../../helper'
import { KTSVG } from '../../../_metronic/helpers'
import { TableColumn } from 'react-data-table-component'
import { QuoteModel, fileType } from '../../../types'
import { RootState } from '../../../setup'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import DataTableBase from '../../components/DataTableBase'
import FilterDropDown from '../../modules/sales/components/FilterDropDown'
import * as serviceOrders from './serviceOrdersRedux'
import * as levelHelper from '../../../helper/level.helper'

interface ReduxStateValueProps {
  customerIds: any
  serviceOrders: QuoteModel[]
  loading: boolean
  auth: IAuthState & PersistPartial
}

const mapState = (state: RootState) => ({
  customerIds: state.auth?.activeCustomerIds,
  serviceOrders: state.serviceOrders.serviceOrders,
  loading: state.serviceOrders.loading,
  auth: state.auth,
})

const connector = connect(mapState, { ...serviceOrders.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type ServiceOrdersProps = ReduxStateValueProps & PropsFromRedux

const ServiceOrdersPage: FC<ServiceOrdersProps> = (props) => {
  const { getServiceOrders, serviceOrders, customerIds, loading, auth } = props
  const initialFilter = {
    status: ['active'],
    from: '',
    to: moment().utc().format("Y-MM-DD HH:mm"),
    isReset: false
  }

  const [isNBS, setIsNBS] = useState<boolean>(levelHelper.isNBS(props.auth.user?.type))
  const [orders, setOrders] = useState<QuoteModel[]>([])
  const [slug, setSlug] = useState<string>('')
  const [quoteId, setQuoteId] = useState<number>(0)
  const [nbsCustomerId, setNbsCustomerId] = useState<string[]>(['nbs'])
  const [attachment, setAttachment] = useState<fileType[]>([])
  const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
  const [filter, setFilter] = useState(initialFilter)

  const handleAttachmentModal = useCallback((dbKey: number) => {
    setShowAttachmentModal(true)
    setQuoteId(dbKey)

    getQuoteAttachment(dbKey)
    .then((res) => {
      let attachments: fileType[] = []

      res.data.forEach((name: string) => {
        attachments.push({ name: name, size: 0, type: 'old' })
      });
      setAttachment(attachments)
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }, [])

  const columns: TableColumn<QuoteModel>[] = useMemo(
    () => [
      {
        name: '',
        cell: (row) =>
        <span
          className='btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable'
          onClick={() => handleAttachmentModal(row.dbkey)}
        >
          <KTSVG
            path='/media/icons/duotone/Communication/paperslip.svg'
            className='svg-icon-2'
          />
          {
            row.attachment && row.attachment.length !== 0 &&
            <span className='attachment-count'>{row.attachment && row.attachment.length}</span>
          }
        </span>,
        width: '40px',
        style: {
          minWidth: '40px !important',
          maxWidth: '40px !important',
        }
      },
      {
        name: '#',
        selector: row => row.dbkey,
        sortable: true,
        width: '100px',
        style: {
          minWidth: '100px !important',
          maxWidth: '100px !important',
        }
      },
      {
        name: 'CUSTOMER',
        selector: row => row.customerId,
        cell: (row) =>
          <span>
            <span>{row.customerId}</span><br />
            <span>{row.customerName}</span>
          </span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        }
      },
      {
        name: 'QUOTE NUMBER',
        selector: row => row.quoteNumber,
        sortable: true,
        style: {
          minWidth: 'unset !important',
          maxWidth: 'unset !important',
        }
      },
      {
        name: 'Transaction Type',
        selector: row => row.transactionType,
        sortable: true,
        style: {
          minWidth: 'unset !important',
          maxWidth: 'unset !important',
        }
      },
      {
        name: 'CREATED ON',
        selector: row => moment(row.createdAt).format("MM/DD/YY"),
        sortable: true,
        style: {
          minWidth: 'unset !important',
          maxWidth: 'unset !important',
        }
      },
      {
        name: 'Attention',
        selector: row => row.attention ?? '',
        sortable: true,
      },
    ],
    [handleAttachmentModal]
  )

  const getOrders = () => {
    getServiceOrders({
      customerIds: isNBS ? nbsCustomerId : customerIds,
      status: filter.status,
      from: filter.from,
      to: filter.to
    })
  }

  const handleFilter = (filters: any) => {
    setFilter(filters)
    const { customerId, status, from, to, isReset } = filters;

    if (isReset === true)
      getServiceOrders({
        customerIds: isNBS ? ['nbs'] : customerIds,
        status: initialFilter.status,
        from: initialFilter.from,
        to: initialFilter.to
      })
    else {
      setNbsCustomerId([customerId])
      getServiceOrders({
        customerIds: isNBS ? customerId === "" ? ['nbs'] : [customerId]: customerIds,
        status: status,
        from: from,
        to: to
      })
    }
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(auth.user?.type))
    getOrders()
  }, [auth])

  useEffect(() => {
    setOrders(
      serviceOrders.filter((orders: QuoteModel) => {
        return (
          orders.dbkey.toString().includes(slug.toUpperCase()) ||
          orders.quoteNumber.toUpperCase().includes(slug.toUpperCase()) ||
          orders.status?.toUpperCase().includes(slug.toUpperCase()) ||
          orders.salesRep?.toUpperCase().includes(slug.toUpperCase()) ||
          orders.attention.toUpperCase().includes(slug.toUpperCase())
        )
      })
    )
  }, [slug])

  useEffect(() => {
    setOrders(serviceOrders)
  }, [serviceOrders])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Service Orders</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className="border-0 pt-6">
          <Card.Title>
            <div className="d-flex align-items-center position-relative my-1">
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type="text"
                data-kt-customer-table-filter="search"
                className="form-control form-control-solid w-250px ps-15 border-0"
                placeholder="Search Sales Quotes"
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
            </div>
          </Card.Title>
          <div className="card-toolbar">
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
              <div className='filter-dropdown ms-3'>
                <button
                  type="button"
                  className="btn btn-light-primary me-3"
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown handleFilter={handleFilter} />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body  className='pt-0 service-quotes-body'>
          <DataTableBase
            columns={columns}
            data={orders}
            progressPending={loading}
            sortFunction={customQuotesSort}
          />
        </Card.Body>
      </Card>
      <AttachmentModal
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        data={attachment}
        quoteId={quoteId}
        getQuotes={getOrders}
      />
    </>
  )
}

export default connector(ServiceOrdersPage)