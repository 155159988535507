import React from 'react'

const NeedService: React.FC = () => {
    return (
        <div className="card card-xl-stretch mb-xl-8 bg-primary">
            <div className="card-body d-flex flex-column pt-13 pb-14">
                {/* begin::Heading */}
                <div className="m-0">
                    {/* begin::Title */}
                    <h1 className="fw-bold text-white text-center lh-lg mb-9">
                        <span className="fw-boldest">Need Service?</span>
                    </h1>
                    {/* end::Title */}
                    {/* begin::Illustration */}
                    <div
                        className="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-20"
                        style={{backgroundImage: 'url("/media/svg/need-service.svg")'}}></div>
                    {/* end::Illustration */}
                </div>
                {/* end::Heading */}
                {/* begin::Links */}
                <div className="text-center">
                    {/* begin::Link */}
                    <a href="/" className="btn btn-sm bg-white btn-color-white bg-opacity-20" data-bs-toggle="tooltip" data-bs-dismiss="click">Get Service Now</a>
                    {/* end::Link */}
                </div>
                {/* end::Links */}
            </div>
        </div>
    )
}

export default NeedService