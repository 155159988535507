import React, { useState, FC } from "react"

type Props ={
  data: { user_id: string, name: string, email: string }[]
  selectedValue: (value: string)=>void
}

const SearchDropDown: FC<Props> = (props) => {
  const [show, setShow] = useState<boolean>(false)
  const [data, setData] = useState<{ user_id: string, name: string, email: string }[]>(props.data)
  const [selected, setSelected] = 
        useState<{ user_id: string, name: string, email: string }>(props.data[0])

  const handleSearch = (e: any) => {
    const input =e.target.value?.toUpperCase()
    setData(props.data?.filter((value) => { 
      return (
        value.name?.toUpperCase().includes(input) === true || value.email?.toUpperCase().includes(input) === true
        )
      })
    )
  }

  return (
    <div className='position-relative'>
      <span className='select2-container' onClick={() => setShow(!show)}>
        <span className='select2-selection select2-selection--single form-select form-select-solid fw-bolder'>
          <span className='select2-selection__rendered'>
            {selected?.name} - {selected?.email}
          </span>
        </span>
      </span>
      {
        show &&
        <div className='select2-container select2-container--bootstrap5 search-dropdown-container'>
          <div className='select2-dropdown'>
            <div className='select2-search select2-search--dropdown'>
              <input type='text' className='select2-search__field w-100' onChange={handleSearch} />
            </div>
            <span className='select2-results'>
              <ul className='select2-results__options'>
                {
                  data?.map((item, index) => {
                    return (
                      <li 
                        key={index} 
                        className={`${selected?.user_id === item.user_id && 'select2-results__option--selected'} select2-results__option select2-results__option--selectable`}
                        onClick={() => {
                          props.selectedValue(item.user_id)
                          setSelected(item)
                          setShow(false)
                        }}
                      >
                        {item.name} - {item.email}
                      </li>
                    )
                  })
                }
              </ul>
            </span>
          </div>
        </div>
      }
    </div>
  )
}

export { SearchDropDown }