import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import { put, takeLatest, select } from 'redux-saga/effects'
import { extractOnlyCustomerIds } from '../../../../helper/customerId.helper'
import { UserModel } from '../../../../types'
import { getUserByToken, logout } from './AuthCRUD'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  LogoutSuccess: '[LogoutSuccess] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetCustomerId: '[Set Customer Id] Action',
  SetActiveCustomerIds: '[Set Active Customer Ids] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  activeCustomerId: undefined,
  activeCustomerIds: [],
  accessToken: undefined,
}

export interface IAuthState {
  user?: UserModel
  activeCustomerId?: string
  activeCustomerIds?: string[]
  accessToken?: string
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        const user = action.payload?.user
        const activeCustomerId = action.payload?.user?.customerIds
          ? action.payload?.user?.customerIds[0].customerId
          : ''
        const activeCustomerIds = extractOnlyCustomerIds(action.payload?.user?.customerIds)
        return {accessToken, user, activeCustomerId, activeCustomerIds}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        const user = action.payload?.user
        return {accessToken, user}
      }

      case actionTypes.LogoutSuccess: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        const activeCustomerId = action.payload?.user?.customerIds
          ? action.payload?.user?.customerIds[0].customerId
          : ''
        const activeCustomerIds = extractOnlyCustomerIds(action.payload?.user?.customerIds)
        return {...state, user, activeCustomerId, activeCustomerIds}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        const activeCustomerId = action.payload?.user?.customerIds
          ? action.payload?.user?.customerIds[0].customerId
          : ''

        return {...state, user, activeCustomerId}
      }

      case actionTypes.SetCustomerId: {
        const activeCustomerId = action.payload?.activeCustomerId
        return {...state, activeCustomerId}
      }

      case actionTypes.SetActiveCustomerIds: {
        const activeCustomerIds = action.payload?.activeCustomerIds
        return {...state, activeCustomerIds}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string, user: UserModel) => ({
    type: actionTypes.Login,
    payload: {accessToken, user},
  }),
  register: (accessToken: string, user: UserModel) => ({
    type: actionTypes.Register,
    payload: {accessToken, user},
  }),
  logout: () => ({type: actionTypes.Logout}),
  logoutSuccess: () => ({type: actionTypes.LogoutSuccess}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  setActiveCustomerId: (custerId: string) => ({
    type: actionTypes.SetCustomerId,
    payload: {activeCustomerId: custerId},
  }),
  setActiveCustomerIds: (custerIds: string[]) => ({
    type: actionTypes.SetActiveCustomerIds,
    payload: {activeCustomerIds: custerIds},
  }),
}

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser())
  // })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })

  yield takeLatest(actionTypes.Logout, function* logoutSaga() {
    const {accessToken} = yield select((state) => state.auth)
    if (accessToken) {
      yield logout().catch((ex) => { console.log(ex) })
      localStorage.setItem('isLoaded', '1')
    }

    yield put(actions.logoutSuccess())
  })
}
