/* eslint-disable no-restricted-imports */
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Card, CardContent, Typography} from '@material-ui/core'

import {PageTitle} from '../../../_metronic/layout/core'
import CalibrationReportsFilter from './CalibrationReportsFilter'
import DataTable from '../../components/Table'

import * as calibrationReports from './calibrationReportsRedux'

const CalibrationReportsPage = (props) => {
  const {getCalibrationReports, calibrationReports, activeCustomerId} = props
  
  useEffect(() => {
    if (activeCustomerId === '')
      getCalibrationReports(0)
    else 
      getCalibrationReports(activeCustomerId)
  }, [getCalibrationReports, activeCustomerId])

  const headRows = [
    {id: 'dbkey', numeric: true, label: 'Seq'},
    {id: 'dateofcal', numeric: true, disablePadding: false, label: 'Date of Cal'},
    {id: 'created_at', numeric: true, disablePadding: false, label: 'Create On'},
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>Calibration Reports</PageTitle>
      <Card>
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            Calibration Reports List
          </Typography>
          <CalibrationReportsFilter />
          <DataTable headRows={headRows} rows={calibrationReports} />
        </CardContent>
      </Card>
    </>
  )
}

const mapStatesToProps = (state) => ({
  activeCustomerId: state.auth?.activeCustomerId,
  calibrationReports: state.calibrationReports.calibrationReports,
})

export default connect(mapStatesToProps, calibrationReports.actions)(CalibrationReportsPage)
