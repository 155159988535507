/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import { Dropdown, Notification } from './components'
import { getUserNotifications, markAsReadNotification } from '../../services/notification'
import { RootState } from '../../../setup'
import { UserModel, NotificationModel } from '../../../types'
import { getErrorMessage } from '../../../helper/response.helper'
import toast from 'react-hot-toast'

type Props = {
  className: string
}

const NotificationList: React.FC<Props> = ({className}) => {
  const [notifications, setNotifications] = useState<NotificationModel[]>([])
  const [unReadCount, setUnreadCount] = useState<number>(0)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const handleMarkRead = (id: string) => {
    markAsReadNotification(id)
      .then((res) => {
        getNotifications()
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }

  const getNotifications = () => {
    getUserNotifications(user.id)
      .then((res) => {
        if (res.data.length !== 0) {
          setNotifications(res.data.notifications)
        }
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }

  useEffect(() => {
    getNotifications()
  }, [user])

  useEffect(() => {
    setUnreadCount(0)

    if (notifications.length !== 0)
      notifications.forEach((notification: NotificationModel) => {
        if (!notification.read_at)
          setUnreadCount(unReadCount + 1)
      })
  }, [notifications])

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Notifications  {unReadCount} unread</h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG
              path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
              className='svg-icon-2'
            />
          </button>
          <Dropdown />
        </div>
      </div>
      <div className='card-body pt-0'>
        {
          notifications.map((notification, index) => {
            return (
              <Notification key={index} data={notification} markRead={handleMarkRead} />
            )
          })
        }
      </div>
      {/* end::Body */}
    </div>
  )
}

export { NotificationList }
