/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import toast from 'react-hot-toast'
import moment from 'moment'
import { Card } from 'react-bootstrap-v5'
import { TableColumn } from 'react-data-table-component'
import { RootState } from '../../../setup'
import { QuoteModel, fileType } from '../../../types'
import { PageTitle } from '../../../_metronic/layout/core'
import { openSalesQuotePdf, rejectQuote, commentQuote, getQuoteAttachment, approveQuote } from './salesQuotesCrud'
import { AttachmentModal } from '../service-quotes/components/AttachmentModal'
import { getErrorMessage, customQuotesSort } from '../../../helper'
import { KTSVG } from '../../../_metronic/helpers'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { Description } from '../service-quotes/components/Description'
import { ActionDropDown } from '../service-quotes/components/ActionDropDown'
import DataTableBase from '../../components/DataTableBase'
import BaseModal from '../../components/_modal/BaseModal'
import FilterDropDown from '../../modules/service/_dropdown/FilterDropDown'
import ApproveModal from '../../components/_modal/ApproveDialog'
import ConfirmAlert from '../../components/ConfirmAlert'
import * as levelHelper from '../../../helper/level.helper'
import * as salesQuotesRedux from './salesQuotesRedux'

interface ReduxStateValueProps {
  salesQuotes: QuoteModel[]
  loading: boolean
  customerIds: any
  auth: IAuthState & PersistPartial
}

const mapState = (state: RootState) => ({
  salesQuotes: state.salesQuotes.salesQuotes,
  loading: state.salesQuotes.loading,
  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, { ...salesQuotesRedux.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type SalesQuotesProps = ReduxStateValueProps & PropsFromRedux

const SalesQuotesPage: React.FC<SalesQuotesProps> = (props) => {
  const initialActionModalsShow = {
    approve: false,
    reject: false,
  }

  const initialFilter = {
    status: ['Active', 'Approved'],
    from: '',
    to: moment().utc().format("Y-MM-DD HH:mm"),
    isReset: false
  }

  const [isNBS, setIsNBS] = useState<boolean>(levelHelper.isNBS(props.auth.user?.type))
  const [slug, setSlug] = useState<string>('')
  const [quotes, setQuotes] = useState<QuoteModel[]>([])
  const [currentQuote, setCurrentQuote] = useState<QuoteModel>()
  const [show, setShow] = useState<boolean>(false)
  const [showComment, setShowComment] = useState<boolean>(false)
  const [quoteId, setQuoteId] = useState<number>(0)
  const [quoteNumber, setQuoteNumber] = useState()
  const [attachment, setAttachment] = useState<fileType[]>([])
  const [nbsCustomerId, setNbsCustomerId] = useState<string[]>(['nbs'])
  const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
  const [actionModalsShow, setActionModalsShow] = useState(initialActionModalsShow)
  const [inProgress, setInProgress] = useState(false)
  const [filter, setFilter] = useState(initialFilter)
  const [approveStatus, setApproveStatus] = useState<boolean>(false)

  const [confirmAlert, setConfirmAlert] = useState({
    show: false,
    content: '',
    onConfirm: () => { },
  })

  const hideActionModals = useCallback(() => {
    setActionModalsShow(initialActionModalsShow)
  }, [actionModalsShow])

  const showConfirmAlert = useCallback((content: string, onConfirm: any) => {
    setConfirmAlert({ show: true, content, onConfirm })
  }, [confirmAlert])

  const handleAttachmentModal = useCallback((dbKey: number) => {
    setShowAttachmentModal(true)
    setQuoteId(dbKey)

    getQuoteAttachment(dbKey)
    .then((res) => {
      let attachments: fileType[] = []

      res.data.forEach((name: string) => {
        attachments.push({ name: name, size: 0, type: 'old' })
      });
      setAttachment(attachments)
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    })
  }, [])

  const getQuotes = () => {
    props.getSalesQuotes({
      customerIds: isNBS ? nbsCustomerId : props.customerIds,
      status: filter.status,
      from: filter.from,
      to: filter.to
    })
  }

  const modalProps = {
    onHide: hideActionModals,
    quoteNumber,
    showConfirmAlert: showConfirmAlert,
  }

  const columns: TableColumn<QuoteModel>[] = useMemo(
    () => [
      {
        name: '',
        cell: (row) =>
        <span
          className='btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable'
          onClick={() => handleAttachmentModal(row.dbkey)}
        >
          <KTSVG
            path='/media/icons/duotone/Communication/paperslip.svg'
            className='svg-icon-2'
          />
          {
            row.attachment && row.attachment.length !== 0 &&
            <span className='attachment-count'>{row.attachment && row.attachment.length}</span>
          }
        </span>,
        width: '40px',
        style: {
          minWidth: '40px !important',
          maxWidth: '40px !important',
        }
      },
      {
        name: 'CUSTOMER',
        selector: row => row.customerId,
        cell: (row) =>
          <span>
            <span>{row.customerId}</span><br />
            <span>{row.customerName}</span>
          </span>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
        }
      },
      {
        name: 'QUOTE NUMBER',
        selector: row => row.quoteNumber,
        maxWidth: 'unset !important',
        sortable: true,
      },
      {
        name: <span style={{ whiteSpace: 'break-spaces' }}>TRANSACTION TYPE</span>,
        selector: row => row.transactionType,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
          marginLeft: '10px',
          padding: '0.7rem 0 !important',
          whiteSpace: 'break-spaces'
        }
      },
      {
        name: 'ATTENTION',
        selector: row => row.attention,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
          marginLeft: '10px',
          padding: '0.7rem 0 !important'
        }
      },
      {
        name: 'CREATED ON',
        selector: row => moment(row.createdAt).format("MM/DD/YY"),
        sortable: true,
        style: {
          maxWidth: 'unset !important',
          marginLeft: '10px',
          padding: '0.7rem 0 !important'
        }
      },
      {
        name: 'NBS REP',
        selector: row => row.salesRep ?? "",
        cell: (row) => <div style={{ whiteSpace: 'break-spaces' }}>{row.salesRep}</div>,
        sortable: true,
        style: {
          maxWidth: 'unset !important',
          marginLeft: '10px',
          padding: '0.7rem 0 !important'
        }
      },
      {
        name: 'Description',
        cell: (row) => <Description data={row} />,
        sortable: true,
        width: '220px',
        style: {
          minWidth: '220px !important',
          maxWidth: '220px !important',
          padding: '0.7rem 14px !important'
        }
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        width: '100px',
        style: {
          maxWidth: 'unset !important',
          marginLeft: '10px',
          padding: '0.7rem 0 !important'
        }
      },
      {
        name: 'Actions',
        cell: row =>
          <ActionDropDown
            data={row}
            handleView={() => handleView(row)}
            handleReject={() => { setShow(true); setCurrentQuote(row) }}
            handleComment={()  => { setShowComment(true); setCurrentQuote(row) }}
            handleApprove={() => handleApprove(row)}
          />,
        style: {
          maxWidth: '100px !important',
          marginLeft: '10px',
          padding: '0.7rem 0 !important'
        }
      }
    ],
    []
  )

  const handleView = (row: any) => {
    openSalesQuotePdf({ dbkey: row?.dbkey })
      .then((res) => {
        window.open(res.data, '_blank')
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }

  const handleReject = (comments: string) => {
    setInProgress(true)
    rejectQuote({
      quoteId: currentQuote?.dbkey === undefined ? 0 : currentQuote?.dbkey,
      comments: comments,
      status: 'reject',
    }).then((res) => {
      toast.success(res.data)
      props.getSalesQuotes({
        customerIds: isNBS ? nbsCustomerId : props.customerIds,
        status: filter.status,
        from: filter.from,
        to: filter.to,
      })
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    }).finally(() => {
      setInProgress(false)
      setShow(false)
    })
  }

  const handleComment = (comments: string) => {
    setInProgress(true)
    commentQuote({
      quoteId: currentQuote?.dbkey === undefined ? 0 : currentQuote?.dbkey,
      comments: comments,
      status: 'comment',
    }).then((res) => {
      toast.success(res.data)

      props.getSalesQuotes({
        customerIds: isNBS ? nbsCustomerId : props.customerIds,
        status: filter.status,
        from: filter.from,
        to: filter.to,
      })
    })
    .catch((error) => {
      const errMsg = getErrorMessage(error)
      toast.error(errMsg)
    }).finally(() => {
      setInProgress(false)
      setShowComment(false)
    })
  }

  const handleApprove = (row: any) => {
    setActionModalsShow({ approve: true, reject: false })
    setQuoteId(row?.dbkey)
    setQuoteNumber(row?.quoteNumber)
  }

  const handleFilter = (filters: any) => {
    const { customerId, status, from, to, isReset } = filters;

    if (isReset === true) {
      setFilter(initialFilter)
      props.getSalesQuotes({
        customerIds: isNBS ? ['nbs'] : props.customerIds,
        status: initialFilter.status,
        from: initialFilter.from,
        to: initialFilter.to,
      })
    } else {
      setFilter(filters)
      setNbsCustomerId([customerId])

      props.getSalesQuotes({
        customerIds: isNBS ? customerId === "" ? ['nbs'] : [customerId] : props.customerIds,
        status: status,
        from: from,
        to: to
      })
    }  
  }

  const handleApproveSubmit = (values: any) => {
    setApproveStatus(false)

    approveQuote(quoteId, values)
      .then(() => {
        getQuotes()
        toast.success('Service Order was approved Successfully. Your NBS Rep has been notified!')
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setApproveStatus(true)
        hideActionModals()
      })
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(props.auth.user?.type))

    props.getSalesQuotes({ 
      customerIds: levelHelper.isNBS(props.auth.user?.type) ? nbsCustomerId : props.customerIds,
      status: filter.status,
      from: filter.from,
      to: filter.to,
    })
  }, [props.customerIds, props.auth])

  useEffect(() => {
    setQuotes(
      props.salesQuotes.filter((quote: QuoteModel) => {
        return (
          quote.customerId.toUpperCase().includes(slug.toUpperCase()) ||
          quote.quoteNumber.toUpperCase().includes(slug.toUpperCase()) ||
          quote.description?.toUpperCase().includes(slug.toUpperCase()) ||
          quote.status.toUpperCase().includes(slug.toUpperCase()) ||
          quote.transactionType.toUpperCase().includes(slug.toUpperCase()) ||
          quote.salesRep?.toUpperCase().includes(slug.toUpperCase()) ||
          quote.attention.toUpperCase().includes(slug.toUpperCase())
        )
      })
    )
  }, [slug])

  useEffect(() => {
    setQuotes(props.salesQuotes)
  }, [props.salesQuotes])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Sales Quotes</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className="border-0 pt-6">
          <Card.Title>
            <div className="d-flex align-items-center position-relative my-1">
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type="text"
                data-kt-customer-table-filter="search"
                className="form-control form-control-solid w-250px ps-15 border-0"
                placeholder="Search Sales Quotes"
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
            </div>
          </Card.Title>
          <div className="card-toolbar">
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
              <div className='filter-dropdown ms-3'>
                <button
                  type="button"
                  className="btn btn-light-primary me-3"
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown handleFilter={handleFilter} />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="pt-0 service-quotes-body">
          <DataTableBase
            columns={columns}
            data={quotes}
            progressPending={props.loading}
            sortFunction={customQuotesSort}
          />
        </Card.Body>
      </Card>
      <BaseModal
        id="service_quote_modal"
        title="Reject Quote"
        content="Are you sure you would like to reject this quote?"
        okText="Yes"
        cancelText="No"
        show={show}
        loading={inProgress}
        handleOk={handleReject}
        handleCancel={() => setShow(false)}
      />
      <BaseModal
        id="service_quote_comment_modal"
        title="Add Comment"
        content="Are you sure you would like to add comment to this quote?"
        okText="Yes"
        cancelText="No"
        show={showComment}
        loading={inProgress}
        handleOk={handleComment}
        handleCancel={() => setShowComment(false)}
      />
      <ApproveModal
        submit={handleApproveSubmit}
        status={approveStatus}
        show={actionModalsShow.approve}
        {...modalProps}
      />
      <AttachmentModal
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        data={attachment}
        quoteId={quoteId}
        getQuotes={getQuotes}
      />
      <ConfirmAlert
        open={confirmAlert.show}
        content={confirmAlert.content}
        onHide={() => setConfirmAlert({ ...confirmAlert, show: false })}
        onConfirm={confirmAlert.onConfirm}
      />
    </>
  )
}

export default connector(SalesQuotesPage)
