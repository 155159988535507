import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {MenuItem} from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import {useIntl} from 'react-intl'
import { UserModel } from '../../../../types'
import { RootState } from '../../../../setup'
import * as levelHelper from '../../../../helper/level.helper'


export function MenuInner() {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    setIsAdmin(levelHelper.isNBS(user?.type))
  }, [user])

  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      {
        isAdmin ?
        <MenuInnerWithSub
          title='Learning Center'
          to='/learning-center'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          <MenuItem 
            to='/learning-center/employees' 
            title='Employees' 
            hasBullet={true} 
          />
          <MenuItem 
            to='/learning-center/customer' 
            title='Customers' 
            hasBullet={true} 
          />
        </MenuInnerWithSub> :
        <MenuItem to='/learning-center/customer' title='Learning Center' />
      }
    </>
  )
}
