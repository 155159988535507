import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { UserModel } from '../../../../types'
import { RootState } from '../../../../setup'
import * as levelHelper from '../../../../helper/level.helper'

export function AsideMenuMain() {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    setIsAdmin(levelHelper.isNBS(user?.type))
  }, [user])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Work Orders</span>
        </div>
      </div>
      <AsideMenuItem
        to='/statistics/service-quotes'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Service Quotes'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/statistics/sales-quotes'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Sales Quotes'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/statistics/sales-orders'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Sales Orders'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/statistics/service-orders'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Service Orders'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/statistics/calibration-reports'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Calibration Reports'
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Profile</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotone/General/User.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem
          to='/crafted/account/assigned-customer-ids'
          title='Customer IDs'
          hasBullet={true}
        />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Asset</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/asset-management'
        title='Asset Management'
        icon='/media/icons/duotone/General/Shield-protected.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem
          to='/asset-management/list'
          title='Asset list'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      {
        isAdmin &&
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin Configuration Setting</span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/admin-configuration-setting'
            title='Receive Notifications and Alerts'
            icon='/media/icons/duotone/General/User.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/admin-configuration-setting/receive-service'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Service Quote Approvals'
            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-sales'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Sales Quote Approvals'

            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-asset'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Asset Updates'

            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-new-service'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive New Service Requests'

            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-get-service'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Get Service Requests'

            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-contactus'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Contact Us Requests'

            />
          </AsideMenuItemWithSub>
          <div className='menu-item'>
            <div className='menu-content'>
              <div className='separator mx-1 my-4'></div>
            </div>
          </div>
        </>
      }
    </>
  )
}
