import React, { FC } from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import { EquipmentType } from '../../../../types'

type Props = {
  equipmentType: string
  equipmentTypes: EquipmentType[]
  selectEquipmentType: (eqType: string) => void
}

const EquipmentDropDown: FC<Props> = (props) => {
  const { equipmentType, equipmentTypes, selectEquipmentType } = props

  return (
    <Dropdown className='mx-4'>
      <Dropdown.Toggle id="dropdown-basic" className='min-width-100px' variant="light" size="sm">
        {equipmentType}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          equipmentTypes.map((eqt, index) => {
            return (
              <Dropdown.Item key={index} href="#"
                onClick={() => {
                  selectEquipmentType(eqt.description)
                }}
              >
                {eqt.description}
              </Dropdown.Item>
            )
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { EquipmentDropDown }