import React, { useEffect, useState, useCallback, useRef } from 'react'
import toast from 'react-hot-toast'
import { Modal } from 'react-bootstrap-v5'
import { convertByteTo } from '../../../../helper/learningCenter.helper'
import { KTSVG } from '../../../../_metronic/helpers'
import { fileType } from '../../../../types'
import { uploadAttachment, downloadAttachment } from '../serviceQuotesCrud'
import { ConfirmModal } from '../../../components/_modal/ConfirmModal'


type Props = {
  show: boolean
  onHide: any
  data: fileType[]
  quoteId: number
  getQuotes: () => void
}

const AttachmentModal: React.FC<Props> = (props) => {
  const { show, onHide, data, quoteId, getQuotes } = props
  const [allFiles, setAllFiles] = useState<File[]>([])
  const [fileName, setFileName] = useState<string>('')
  const [fileType, setFileType] = useState<string>('')
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<fileType[]>(data)

  const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const openInputFile = () => {
    inputFileRef.current.click()

    return false
  }

  const handleChange = (event: any) => {
    const files = event.currentTarget.files
    let attachedFiles = [...allFiles, ...files]
    let dt = new DataTransfer()

    attachedFiles.forEach((file) => {
      dt.items.add(file) 
    })

    let fetchFiles = []

    for (let file of files)
      fetchFiles.push({ name: file.name, size: file.size, type: 'new' })
    
    setSelectedFiles([...selectedFiles, ...fetchFiles])
    setAllFiles(attachedFiles)
  }

  const handleCancelFile = useCallback((event: any, name: string, type: string) => {
    event.stopPropagation()

    setFileName(name)
    setFileType(type)
    setShowConfirmDelete(true)
  }, [])

  const handleYesDeleteFile = useCallback(() => {
    let newSelectedFiles: fileType[] = []

    selectedFiles.forEach(file => {
      if (file.name === fileName && file.type === 'old')
        newSelectedFiles.push({ name: file.name, size: file.size, type: 'removed' })
      else if (file.name === fileName && file.type === 'new')
        return
      else
        newSelectedFiles.push(file)
    })

    setSelectedFiles(newSelectedFiles)

    let removedFiles = []

    for (let file of allFiles) {
      if (file?.name !== fileName)
        removedFiles.push(file)
    }

    if (fileType === 'old') {
      uploadAttachment(quoteId, newSelectedFiles, {'files': []})
      .then((res) => {
        getQuotes()
      })
    }
    
    setAllFiles(removedFiles)
    setShowConfirmDelete(false)
  }, [allFiles, selectedFiles, fileName, quoteId, fileType, getQuotes])

  const handleDownLoad = useCallback((event: any, name: string) => {
    event.stopPropagation()

    downloadAttachment(name)
    .then((res) => {
      let url = window.URL.createObjectURL(res.data)
      let a = document.createElement('a')
      a.href = url
      a.download = name
      a.click();
    })
    .catch((error) => {
      toast.error('This file is not found.')
    })
  }, [])

  const handleRemoveAll = () => {
    onHide()
    setSelectedFiles([])
    setAllFiles([])
    setShowConfirm(false)
  }

  const handleConfirmReturn = () => {
    setShowConfirm(false)
    setShowConfirmDelete(false)
  }

  const handleUploadAll = () => {
    let dt = new DataTransfer()

    allFiles.forEach((file) => {
      dt.items.add(file)
    })

    const attachFiles = {
      'files': dt.files
    }

    uploadAttachment(quoteId, selectedFiles, attachFiles)
    .then((res) => {
      setSelectedFiles([])
      setAllFiles([])
      onHide()
      getQuotes()
    })
  }

  useEffect(() => {
    setSelectedFiles(data)
  }, [data])

  return (
    <>
    <Modal size='lg' 
      show={show}
      dialogClassName="w-50"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id="contained-modal-title-vcenter">
          <div className='d-flex justify-content-between'>
            <h2>Service Quote-Attachment</h2>
            <span className='text-hover-primary cursor-pointer' onClick={() => setShowConfirm(true)}>
              <KTSVG
                path='/media/icons/duotone/Navigation/Close.svg'
                className='svg-icon-1'
              />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <div className='form-group'>
          <div className='dropzone dropzone-queue mb-2'>
            <div className='dropzone-panel d-flex justify-content-between align-items-center mb-4'>
              <span className='required'>Attachment</span>
              <span className='dropzone-select btn btn-sm btn-primary dz-clickable' onClick={openInputFile}>
                <KTSVG
                  path='/media/icons/duotone/Files/fil018.svg'
                  className='svg-icon-2'
                />
                Upload files
                <input
                  type='file'
                  name='files[]'
                  className='form-control d-none'
                  onChange={handleChange}
                  ref={inputFileRef}
                  multiple
                />
              </span>
            </div>
          </div>
          <div className='dropzone-items wm-200px'>
            {
              selectedFiles.map((file, index) => {
                return (
                  file.type !== 'removed' &&
                  <div
                    className='d-flex justify-content-between p-4 background-light-dark my-2 cursor-pointer'
                    onClick={(event) => handleDownLoad(event, file.name)}
                    key={index}
                  >
                    <span>{file.name} {file.size !== 0 && `(${convertByteTo(file.size)})`}</span>
                    <span
                      className='text-hover-primary cursor-pointer'
                      onClick={(event) => handleCancelFile(event, file.name, file.type)}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Close.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                )
              })
            }
          </div>
          <span className='form-text fs-6 text-muted'>Max file size is 100MB per file.</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <span
            className='btn btn-sm btn-light-primary me-2'
            onClick={handleRemoveAll}
          >
            Cancel
          </span>
          {
            allFiles.length !== 0 &&
            <span
              className='dropzone-select btn btn-sm btn-primary'
              onClick={handleUploadAll}
            >
              Add attachment
            </span>
          }
        </div>
      </Modal.Footer>
    </Modal>
    {
      showConfirm &&
      <ConfirmModal
        content='Are you sure you would like to cancel?'
        yesText='Yes, cancel it!'
        noText='No, return'
        selectYes={handleRemoveAll}
        selectNo={handleConfirmReturn}
      />
    }
    {
      showConfirmDelete &&
      <ConfirmModal
        content='Are you sure you would like to delete?'
        yesText='Yes, delete it!'
        noText='No, return'
        selectYes={handleYesDeleteFile}
        selectNo={handleConfirmReturn}
      />
    }
    </>
  )
}

export { AttachmentModal }