import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { Formik, Form, Field } from 'formik'
import { getAllUsers, addNewUser } from '../ReceiveAlertsCrud'
import { SearchDropDown } from './SearchDropDown'
import toast from 'react-hot-toast'

type Props = {
  show: boolean
  userId: number
  section: string
  onHide: any
  getApprovals: () => void
}

const AddUserModal: React.FC<Props> = (props) => {
  const { show, userId, section, onHide, getApprovals } = props
  const [notification, setNotification] = useState<boolean>(true)
  const [email, setEmail] = useState<boolean>(true)
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [users, setUsers] = useState<{ user_id: string, name: string, email: string }[]>([])

  const initialValues = {
    notification: '',
    email: ''
  }

  const handleSubmit = () => {
    const newUser = {
      user_id: selectedUserId,
      section: section,
      notification: notification,
      email: email,
    }
    
    addNewUser(newUser)
    .then((res) => {
      toast.success(res.data)
      getApprovals()
      onHide(false)
    })
  }

  const selectUser = (value: string) => {
    setSelectedUserId(value)
  }

  useEffect(() => {
    getAllUsers(userId)
    .then((res) => {
      setUsers(res.data)
      setSelectedUserId(res.data[0]?.user_id)
    })
  }, [userId])

  return (
    <Modal size='lg' 
      show={ show }
      dialogClassName="w-50"
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id="contained-modal-title-vcenter">
          <div className='d-flex justify-content-between'>
            <h2>Add New User</h2>
            <span className='text-hover-primary cursor-pointer' onClick={onHide}>
              <KTSVG
                path='/media/icons/duotone/Navigation/Close.svg'
                className='svg-icon-1'
              />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, errors, touched, values }) => (
            <Form>
              <div className='d-flex flex-column scroll-y me-n7 pe-7 min-height-40 max-height-60'>
              <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <label className='required fw-bold fs-6 mb-2'>User ID</label>
                  <SearchDropDown data={users} selectedValue={selectUser} />
                </div>
                <div className='fv-row mb-7 fv-plugins-icon-container d-flex justify-content-between'>
                  <span className='fw-bold fs-6 mb-2'>Receive by notification</span>
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                    <Field
                      as='input'
                      name='notification'
                      type='checkbox'
                      className='form-check-input'
                      onChange={() => setNotification(!notification)}
                      checked={notification}
                    />
                  </label>
                </div>
                <div className='fv-row mb-7 fv-plugins-icon-container d-flex justify-content-between'>
                <span className='fw-bold fs-6 mb-2'>Receive by email</span>
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                    <Field
                      as='input'
                      name='email'
                      type='checkbox'
                      className='form-check-input'
                      onChange={() => setEmail(!email)}
                      checked={email}
                    />
                  </label>
                </div>
              </div>
              <div className='text-center pt-15'>
                <button
                  type='button'
                  className='btn btn-light me-3'
                  onClick={() => onHide(true)}
                >
                  Discard
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      </Modal>
  )
}

export { AddUserModal }