import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { QuoteModel } from '../../../types'
import { getServiceOrders } from './serviceOrdersCrud'

export interface ActionWithPayload<T> extends Action {
  payload? : T
}

export const actionTypes = {
  ServiceOrdersRequested: '[GetServiceOrders] Action',
  ServiceOrdersLoaded: "[Load Service Orders] Action",
}

const initialQuotesState: IServiceOrdersState = {
  serviceOrders: [],
  loading: false,
}

export interface IServiceOrdersState {
  serviceOrders: any
  loading: boolean
}

export const reducer = (
  state: IServiceOrdersState = initialQuotesState,
  action: ActionWithPayload<IServiceOrdersState>
) => {
  switch (action.type) {
    case actionTypes.ServiceOrdersRequested: {
      return {...state, loading: true}
    }
    case actionTypes.ServiceOrdersLoaded: {
      const serviceOrders = action.payload?.serviceOrders
      return { loading: false, serviceOrders }
    }
    default:
      return state
  }
}

export const actions = {
  getServiceOrders: (searchFilter = {}) => ({
    type: actionTypes.ServiceOrdersRequested,
    payload: { searchFilter },
  }),
  serviceOrdersLoaded: (serviceOrders: QuoteModel[]) => ({ 
    type: actionTypes.ServiceOrdersLoaded, 
    payload: { serviceOrders } 
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.ServiceOrdersRequested, function* serviceOrdersRequested(action: any) {
    const { searchFilter } = action.payload
    const { data: serviceOrders } = yield getServiceOrders(searchFilter)

    yield put(actions.serviceOrdersLoaded(serviceOrders))
  })
}
