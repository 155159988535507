/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import moment from 'moment'
import { RootState } from '../../../setup'
import { PageTitle } from '../../../_metronic/layout/core'
import { SalesStatistics, InvitedCustomers, NotificationList, NeedService } from '../../modules'
import { BlogModel, UserModel } from '../../../types'
import CustomersTable from '../customer/component/CustomersTable'
import NBSAdminTable from '../nbs-admin/component/NBSAdminTable'
import BlogForm from '../../modules/blog'
import BlogList from '../../modules/blog/BlogList'
import * as levelHelper from '../../../helper/level.helper'
import * as customersRedux from '../customer/redux/customerRedux'
import * as nbsAdminsRedux from '../nbs-admin/redux/nbsAdminRedux'
import * as blogsRedux from '../../modules/blog/redux/blogRedux'
import * as serviceQuoteRedux from '../service-quotes/serviceQuotesRedux'
import * as salesQuoteRedux from '../sales-quotes/salesQuotesRedux'
import * as serviceOrderRedux from '../service-orders/serviceOrdersRedux'
import * as salesOrderRedux from '../sales-orders/salesOrdersRedux'


interface ReduxStateValueProps {
  isAdmin: boolean
  isNBS: boolean
  customers: UserModel[]
  serviceQuoteCount: number
  salesQuoteCount: number
  serviceOrderCount: number
  salesOrderCount: number
  nbsAdmins: UserModel[]
  blogs: BlogModel[]
  user: UserModel | undefined
  loadingBlogs: boolean
}
const DashboardPage: FC<ReduxStateValueProps> = ({
  isAdmin,
  isNBS,
  customers,
  serviceQuoteCount,
  salesQuoteCount,
  serviceOrderCount,
  salesOrderCount,
  user,
  nbsAdmins,
  blogs,
  loadingBlogs,
}) => {
  const [blog, setBlog] = useState<BlogModel | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  
  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <SalesStatistics
            serviceQuoteCount={serviceQuoteCount}
            salesQuoteCount={salesQuoteCount}
            serviceOrderCount={serviceOrderCount}
            salesOrderCount={salesOrderCount}
            className='card-xl-stretch mb-xl-8'
            color='danger'
          />
        </div>
        <div className='col-xxl-8'>
          <BlogList
            data={blogs}
            loading={loadingBlogs}
            isNBS={isNBS}
            className='card-xxl-stretch mb-xl-8 mb-5'
            handleEdit={(blog) => {
              setIsEdit(true)
              setBlog(blog)
            }}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {isNBS && (
        <div className='row gy-5 gx-xl-8'>
          <div className='col-xl-12'>
            <CustomersTable customers={customers} />
          </div>
          <div className='col-xl-12'>
            <NBSAdminTable data={nbsAdmins} />
          </div>
          <div className='col-xl-12' id='kt_blog_form_container'>
            <BlogForm
              isEdit={isEdit}
              values={blog}
              handleCancel={() => {
                setIsEdit(false)
                setBlog(null)
              }}
            />
          </div>
        </div>
      )}

      {/* end::Row */}

      {/* begin::Row */}
      {!isNBS && (
        <div className='row gy-5 g-xl-8'>
          <div className='col-xl-4'>
            <NeedService />
          </div>
          <div className='col-xl-4'>
            <InvitedCustomers customers={customers} admin={isAdmin} />
          </div>
          <div className='col-xl-4'>
            <NotificationList className='card-xl-stretch mb-xl-8' />
          </div>
        </div>
      )}
      {/* end::Row */}
    </>
  )
}

const mapState = (state: RootState) => ({
  customers: state.customers.customers,
  nbsAdmins: state.nbsAdmins.nbsAdmins,
  serviceQuotes: state.serviceQuotes.serviceQuotes,
  salesQuotes: state.salesQuotes.salesQuotes,
  serviceOrders: state.serviceOrders.serviceOrders,
  salesOrders: state.salesOrders.salesOrders,
  blogs: state.blogs.blogs,
  loading: state.customers.loading,
  loadingBlogs: state.blogs.loading,
  auth: state.auth,
})
const connector = connect(mapState, {
  ...customersRedux.actions,
  ...nbsAdminsRedux.actions,
  ...blogsRedux.actions,
  ...serviceQuoteRedux.actions,
  ...salesQuoteRedux.actions,
  ...serviceOrderRedux.actions,
  ...salesOrderRedux.actions
})
type PropsFromRedux = ConnectedProps<typeof connector>
type CustomerDataProps = ReduxStateValueProps & PropsFromRedux

const DashboardWrapper: FC<CustomerDataProps> = (props) => {
  const isAdmin = levelHelper.isCustomerAdmin(props.auth.user?.level)
  const isNBS = levelHelper.isNBS(props.auth.user?.type)
  const name = `${props.auth.user?.firstname} ${props.auth.user?.lastname}`
  
  useEffect(() => {
    props.getBlogs({slug: null, from: null, to: null})
    
    if (isNBS) {
      props.getNBSAdmins()
    }
  }, [])

  useEffect(() => {
    props.getCustomers(props.auth.activeCustomerIds)

    props.getServiceQuotes({
      customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
      status: ['Active', 'Approved'], 
      from: 'Invalid date',
      to: moment(new Date()).format("YYYY-MM-DD hh:mm"),
      transType: ['Cal_Lab', 'Service', 'Repair'],
    })

    props.getSalesQuotes({
      customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
      status: ['Active', 'Approved'], 
      from: '',
      to: moment(new Date()).format("YYYY-MM-DD hh:mm")
    })

    props.getServiceOrders({
      customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
      status: ['active'],
      from: '',
      to: moment().utc().format("Y-MM-DD HH:mm"),
    })

    props.getSalesOrders({
      customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
      status: ['active'],
      from: '',
      to: moment().utc().format("Y-MM-DD HH:mm"),
    })
  }, [props.auth.activeCustomerIds])
  
  const pageTitle = isNBS ? `NBS Admin dashboard` : 'Customer dashboard'

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {`${pageTitle} - Logged in as ${name}`}
      </PageTitle>
      <DashboardPage
        isAdmin={isAdmin}
        isNBS={isNBS}
        customers={props.customers}
        serviceQuoteCount={props.serviceQuotes?.length}
        salesQuoteCount={props.salesQuotes?.length}
        serviceOrderCount={props.serviceOrders?.length}
        salesOrderCount={props.salesOrders?.length}
        nbsAdmins={props.nbsAdmins}
        user={props.auth.user}
        blogs={props.blogs}
        loadingBlogs={props.loadingBlogs}
      />
    </>
  )
}

export default connector(DashboardWrapper)
