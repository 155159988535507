import React from 'react';
import DataTable, { TableProps } from 'react-data-table-component';
import { Form } from 'react-bootstrap-v5'

const customStyles = {
    headRow: {
        style: {
            borderBottomStyle: "dashed",
            borderBottomColor: "#eff2f5",
            color: "#b5b5c3!important",
            textTransform: "uppercase!important",
            fontWeight: "600!important",
            fontSize: ".95rem!important"
        }
    },
    headCells: {
        style: {
            padding: "1.25rem 0",
            '&:last-of-type': {
				justifyContent: 'flex-end'
			},
        }
    },
    rows: {
        style: {
            borderBottomStyle: "dashed !important",
            borderBottomColor: "#eff2f5 !important",
            '&:last-of-type': {
				border: 'none !important'
			},
        }
    },
    cells: {
        style: {
            padding: "1.25rem 0",
            maxWidth: '200px !important',
            '&:last-of-type': {
				justifyContent: 'flex-end'
			},
        }
    },
    pagination: {
        style: {
            border: 'none',
            justifyContent: 'space-between',
            '& > span': {
				display: 'none'
			},
            '& > div': {                                
                '& > select': {                                        
                    backgroundColor: '#f5f8fa',
                    padding: '6px 40px 6px 10px',
                    borderRadius: '.475rem',
                    '&:focus-visible': {
                        outline: 'none',
                    }
                },
                '& > svg': {
                    top: '6px'
                }
            }
        }
    }
};

const CustomCheckbox = React.forwardRef((props: any, ref: any) => {
    return (
        <Form.Check ref={ref} className="form-check form-check-sm form-check-custom form-check-solid" {...props}></Form.Check>
    )
})

function DataTableBase<T>(props: TableProps<T>): JSX.Element {
    return (
        <DataTable
            pagination
            selectableRows
            customStyles={customStyles}
            selectableRowsComponent={CustomCheckbox}
            progressComponent={
                <span className="p-4">
                    Loading...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            }
            dense
            {...props}
        />
    );
}

export default DataTableBase;