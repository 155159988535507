import React, { FC } from 'react'
import { QuoteModel } from '../../../../types'

type Props = {
  data: QuoteModel
}

const Description: FC<Props> = (props) => {
  const { data } = props
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        {
          data?.quantity > 0 && 
          <span className='text-muted fw-bold text-muted d-block fs-7'>Qty: {data?.quantity}</span>
        }
        {
          data?.partNumber !== '' && 
          <span className='text-muted fw-bold text-muted d-block fs-7'>Part: {data?.partNumber}</span>
        }
        {
          data?.description !== '' && 
          <span className='text-muted fw-bold text-muted d-block fs-7'>
            Description: {data.description?.substring(0, 50)}
          </span>
        }
      </div>
    </div>
  )
}

export { Description }